import React from 'react';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { SearchInput } from '../../../components/ui/TextInput/SearchInput.styled';
import { createSortProps } from '../../../utils/table.utils';
import { LocationOnboardingInfo } from '../../../graphql/query/locationsInOnboarding';

const { Column } = Table;
const PageContent = styled.div`
  padding: 10px 10px;
  width: 100%;
  align-items: flex-start;
`;
const PageHeader = styled.div`
  width: 100%;
  height: 56px;
  padding: 12px 16px;
  border-bottom: 1px solid ${(props) => props.theme.colors.dimGray.plus3};
  display: flex;
  align-items: center;

  justify-content: space-between;
`;
interface OnboardingColumnParams {
  locations: LocationOnboardingInfo[];
  handleSearch: (value: string) => void;
}
export const OnboardingColumn: React.FC<OnboardingColumnParams> = ({ locations, handleSearch }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (locations) {
      handleSearch(value);
    }
  };

  // TODO: Add a button to mark location fully onboarded

  return (
    <>
      <PageHeader>
        <SearchInput placeholder="Search by location or organization" onChange={handleChange} removeBorder />
      </PageHeader>
      <PageContent>
        <Table className="wide-table" dataSource={locations} rowKey="id">
          <Column<LocationOnboardingInfo>
            title="Organization"
            width="160px"
            dataIndex="organization"
            key="organization"
            render={(organization) => organization.name}
            {...createSortProps<LocationOnboardingInfo>({
              type: 'string',
              field: 'organizationName',
              multiple: 1,
            })}
          />
          <Column<LocationOnboardingInfo>
            title="Location"
            width="240px"
            dataIndex="name"
            key="name"
            //@ts-ignore
            render={(name, row) => <Link to={`/app/organization/${row.organization.id}`}>{name}</Link>}
          />
          <Column<LocationOnboardingInfo>
            title="Stage"
            width="160px"
            dataIndex="onboardingStage"
            key="onboardingStage"
            render={(onboardingStage) => <div>{onboardingStage}</div>}
            {...createSortProps<LocationOnboardingInfo>({
              type: 'string',
              field: 'onboardingStage',
              multiple: 1,
            })}
          />
        </Table>
      </PageContent>
    </>
  );
};
