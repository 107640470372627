import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { SideMenuLayout } from '../../components/Layout/SideMenuLayout';
import { OnboardingColumn } from './OnboardingColumn/OnboardingColumn';
import { RadioGroup, RadioGroupOption } from '../../components/ui/RadioGroup/RadioGroup';
import { RadioChangeEvent } from 'antd';
import { LocationOnboardingInfo, useLocationsInOnboardingQuery } from '../../graphql/query/locationsInOnboarding';
import { Spacer } from '../../components/ui/Spacer';
import { FilterByNumber } from '../../components/ui/Filters';

const MenuWrapper = styled.div`
  padding: 16px 16px;
  width: 100%;
`;

const FilterWrapper = styled.div`
  align-items: left;
  text-align: left;
`;

// TODO:
// Fetch the locations up here where the filter is an pass them insto the onboardinpagecolumn
// Add a ALL / PARTNER filter
// Add a stage filter

enum AccountType {
  ALL = 'ALL',
  PARTNER = 'Partner',
}

enum OnboardingStage {
  InAccountSetup = 'In Account Setup',
  InUserSetup = 'In User Setup & Configuration',
  InInventorySetup = 'In Inventory Setup',
  FullyOnboarded = 'Fully Onboarded',
  OnboardingConfirmed = 'Onboarding Confirmed',
}

const accountTypeOptions: RadioGroupOption[] = [
  { label: 'All', value: AccountType.ALL },
  { label: 'Partner', value: AccountType.PARTNER },
];

const OnboardingDashboard: React.FC = () => {
  const [accountType, setAccountType] = useState<AccountType>(AccountType.ALL);
  const { locationsInOnboarding: locations, refetch, error } = useLocationsInOnboardingQuery({});
  const [filters, setFilters] = useState<OnboardingStage[]>([]);
  const [filteredLocations, setFilteredLocations] = useState<LocationOnboardingInfo[]>([]);

  useEffect(() => {
    if (locations.length && filters.length) {
      const filteredLocations = locations.filter((location) =>
        filters.includes(location.onboardingStage as OnboardingStage)
      );
      setFilteredLocations(filteredLocations);
    } else if (!error) {
      setFilteredLocations(locations);
    }
  }, [filters, locations, error]);

  const handleChangeCondition = ({ target: { value } }: RadioChangeEvent) => {
    setAccountType(value);
    if (value === AccountType.PARTNER) {
      refetch({ partnersOnly: true });
    } else {
      refetch({ partnersOnly: false });
    }
  };

  const handleSearch = useCallback(
    (search: string) => {
      if (search) {
        refetch({
          partnersOnly: AccountType.PARTNER === accountType,
          search,
        });
      }
    },
    [accountType, refetch]
  );

  return (
    <SideMenuLayout
      title="Onboarding"
      sideColumn={
        <MenuWrapper>
          <RadioGroup
            size="middle"
            defaultValue={accountType}
            onChange={handleChangeCondition}
            options={accountTypeOptions}
          ></RadioGroup>
          <Spacer type="stack" size="s" />
          <FilterWrapper>
            <FilterByNumber
              title="Onboarding Stage"
              selectedItems={filters}
              options={[
                { id: OnboardingStage.InAccountSetup, name: 'Account Setup' },
                { id: OnboardingStage.InUserSetup, name: 'User Setup & Config' },
                { id: OnboardingStage.InInventorySetup, name: 'Inventory Setup' },
                { id: OnboardingStage.FullyOnboarded, name: 'Fully Onboarded' },
              ]}
              onChange={(checkedValue) => setFilters(checkedValue as OnboardingStage[])}
            />
          </FilterWrapper>
        </MenuWrapper>
      }
      contentColumn={<OnboardingColumn locations={filteredLocations} handleSearch={handleSearch} />}
    />
  );
};

export default OnboardingDashboard;
