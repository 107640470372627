import React from 'react';
import { Dropdown, Menu, message } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';

const TEXT_NUMBER = '+14352911432';
const SUPPORT_EMAIL = 'support@localshift.io';
const VIDEO_LINK = 'https://youtu.be/cNgGpH1WoFQ';

const HelpMenu = () => {
  const handleMenuClick = (e: any) => {
    if (e.key === '1') {
      window.open(`sms:${TEXT_NUMBER}`, '_blank'); // Trigger the SMS action
    } else if (e.key === '2') {
      window.location.href = `mailto:${SUPPORT_EMAIL}`;
    } else if (e.key === '3') {
      window.open(VIDEO_LINK, '_blank');
    } else {
      message.info('Clicked menu item.');
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">Text us: {TEXT_NUMBER}</Menu.Item>
      <Menu.Item key="2">Email us: {SUPPORT_EMAIL}</Menu.Item>
      <Menu.Item key="3">Watch Dashboard Tutorial</Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <QuestionCircleFilled style={{ fontSize: '28px' }} />
    </Dropdown>
  );
};

export default HelpMenu;
