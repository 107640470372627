import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const widgetIsVisible = () => {
  return !!getWidget();
};

const getWidget = () => document.getElementById('podium-website-widget');

export const usePodiumWebchat = () => {
  const location = useLocation();

  useEffect(() => {
    const showWidget = location.pathname.includes('/dashboard');

    if (showWidget) {
      if (!widgetIsVisible()) {
        const script = document.createElement('script');
        script.id = 'podium-widget';
        script.src = 'https://connect.podium.com/widget.js#ORG_TOKEN=f4c7ffab-0999-475b-9dde-5b54f8575e52';
        script.defer = true;
        script.setAttribute('data-organization-api-token', 'f4c7ffab-0999-475b-9dde-5b54f8575e52');

        document.body.appendChild(script);
      }
    }

    return () => {
      // Cleanup: Optionally remove the script if navigating away from the specified pages
      if (!showWidget) {
        const widget = getWidget();
        if (widgetIsVisible() && widget) {
          document.body.removeChild(widget);
        }
      }
    };
  }, [location.pathname]);
};
