import React from 'react';
import styled from 'styled-components';
import { LabelBodyB } from '../../ui/Text/Text.styled';
import HelpMenu from '../../ui/DashboardContent/components/HelpMenu';

const DashboardWrapper = styled.main`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.header`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.dimGray.plus3};
  display: flex;
  background-color: ${(props) => props.theme.colors.white};
  justify-content: space-between;
  padding: 16px;
`;

const BodyContainer = styled.section`
  height: 100%;
  display: flex;
  background-color: #f9fafb;
  flex-direction: column;
  padding: 40px;
`;

export interface DashboardLayoutProps {
  mainContent?: JSX.Element;
  menu?: JSX.Element;
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ mainContent, menu }) => {
  return (
    <DashboardWrapper>
      <HeaderContainer>
        <LabelBodyB>Dashboard</LabelBodyB>
        <HelpMenu />
      </HeaderContainer>
      <BodyContainer>
        {menu}
        {mainContent}
      </BodyContainer>
    </DashboardWrapper>
  );
};

export default DashboardLayout;
