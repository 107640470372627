import React from 'react';
import { DashboardFilter } from '../../../../pages/Dashboard/hooks/useFilters';
import { CardStyled } from './Components.styles';
import { Spin, Table, Tooltip } from 'antd';
import Column from 'antd/lib/table/Column';
import {
  HealthState,
  MarketplaceMetrics,
  MarketplaceOnboardingStep,
  useGetMarketplaceMetrics,
} from '../../../../graphql/query/getMarketplaceMetrics';
import { LabelBodyB } from '../../Text/Text.styled';
import { TagLabel } from '../../TagLabel';
import styled from 'styled-components';
import { Spacer } from '../../Spacer';
import { match } from 'ts-pattern';

interface MarketplaceLeaderboardProps {
  dashboardFilters: DashboardFilter;
}

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
}
`;

const OnboardingStepTitles = {
  [MarketplaceOnboardingStep.InstallExtension]: 'Install Extension',
  [MarketplaceOnboardingStep.ConnectInventory]: 'Connect Inventory',
  [MarketplaceOnboardingStep.PostVehicle]: 'Post Vehicle',
  [MarketplaceOnboardingStep.Complete]: 'Complete',
};

export const MarketplaceLeaderboard: React.FC<MarketplaceLeaderboardProps> = ({ dashboardFilters }) => {
  const { marketplaceMetrics, loading } = useGetMarketplaceMetrics({
    marketplaceMetricsRequest: {
      organizationIds: dashboardFilters.organizationIds,
      startDate: dashboardFilters.dateRange.startDate,
      endDate: dashboardFilters.dateRange.endDate,
      locationIds: dashboardFilters.locationIds,
    },
  });

  if (loading) {
    return (
      <LoadingWrapper>
        <Spin size="large" />
      </LoadingWrapper>
    );
  }

  if (marketplaceMetrics.length === 0) return null;

  const showSoldColumn = marketplaceMetrics.some((metric) => metric.sales > 0);

  const metricTotals = marketplaceMetrics.reduce(
    (acc, metric) => {
      acc.activePosts += metric.activePosts ?? 0;
      acc.totalPosts += metric.totalPosts ?? 0;
      acc.leads += metric.leads ?? 0;
      acc.sales += metric.sales ?? 0;
      acc.revenue += metric.revenue ?? 0;
      return acc;
    },
    { activePosts: 0, totalPosts: 0, leads: 0, sales: 0, revenue: 0 }
  );

  return (
    <CardStyled>
      <LabelBodyB>Facebook Marketplace Leaderboard</LabelBodyB>
      <Table
        pagination={false}
        scroll={{ y: 500 }}
        className="wide-table"
        dataSource={marketplaceMetrics}
        rowKey="name"
      >
        <Column
          title="Onboarding Step"
          dataIndex="onboardingStep"
          key="onboardingStep"
          render={(_name, record: MarketplaceMetrics) => {
            const onboardingStep = record.onboardingStep;
            if (!onboardingStep) return;

            const title = OnboardingStepTitles[onboardingStep];
            const color = onboardingStep === MarketplaceOnboardingStep.Complete ? 'greenLight' : 'dimgray';
            return <TagLabel color={color}>{title}</TagLabel>;
          }}
        />
        <Column
          title="Installed"
          dataIndex="installedAt"
          key="installedAt"
          render={(_name, record: MarketplaceMetrics) => {
            return Boolean(record.installedAt) ? (
              <TagLabel color={'greenLight'}>Yes</TagLabel>
            ) : (
              <TagLabel>No</TagLabel>
            );
          }}
        />
        <Column
          title="Health"
          dataIndex="healthStatus"
          key="healthStatus"
          render={(_name, record: MarketplaceMetrics) => {
            if (!record.healthStatus) return <TagLabel color={'dimgray'}>No Data</TagLabel>;

            const health = record.healthStatus.health;
            const tag = match(health)
              .with(HealthState.Great, () => <TagLabel color={'greenLight'}>Great</TagLabel>)
              .with(HealthState.Okay, () => <TagLabel color={'default'}>Able to Post</TagLabel>)
              .with(HealthState.AbleToPost, () => <TagLabel color={'default'}>Able to Post</TagLabel>)
              .with(HealthState.Poor, () => <TagLabel color={'red'}>Unable to Post</TagLabel>)
              .with(HealthState.UnableToPost, () => <TagLabel color={'red'}>Unable to Post</TagLabel>)
              .exhaustive();

            const updatedAt = record.healthStatus.updatedAt
              ? new Date(record.healthStatus.updatedAt).toLocaleString()
              : null;
            const tooltipElement = (
              <>
                {updatedAt && <div>Updated at: {updatedAt}</div>}
                {record.healthStatus.reasons.map((reason) => (
                  <div>{reason}</div>
                ))}
              </>
            );

            return (
              <Tooltip title={tooltipElement}>
                <>{tag}</>
              </Tooltip>
            );
          }}
        />
        <Column
          title="Name"
          dataIndex="name"
          key="name"
          render={(_name, record: MarketplaceMetrics) => {
            if (record.name && record.profileId) {
              return (
                <a target="_blank" href={`https://www.facebook.com/marketplace/profile/${record.profileId}`}>
                  {record.name}
                </a>
              );
            }
            if (record.profileId) {
              return (
                <a target="_blank" href={`https://www.facebook.com/marketplace/profile/${record.profileId}`}>
                  View Profile
                </a>
              );
            }

            return record.name || '-';
          }}
        />
        <Column
          title="Active Posts"
          dataIndex="activePosts"
          key="activePosts"
          sortOrder={'descend'}
          sorter={(a: MarketplaceMetrics, b: MarketplaceMetrics) => a.activePosts - b.activePosts}
          render={(_name, record: MarketplaceMetrics) => {
            return record.activePosts || '-';
          }}
        />
        <Column
          title="Total Posts"
          dataIndex="totalPosts"
          key="totalPosts"
          render={(_name, record: MarketplaceMetrics) => {
            return record.totalPosts || '-';
          }}
        />
        <Column
          title="Leads"
          dataIndex="leads"
          key="leads"
          render={(_name, record: MarketplaceMetrics) => {
            return record.leads || '-';
          }}
        />
        <Column
          title="Snoozed Until"
          dataIndex="snoozedUntil"
          key="snoozedUntil"
          render={(_name, { snoozedUntil }: MarketplaceMetrics) => {
            if (!snoozedUntil) return '-';

            const date = new Date(snoozedUntil);
            const options = { weekday: 'long' as const, hour: 'numeric' as const, minute: 'numeric' as const };
            const formattedDate = date.toLocaleDateString('en-US', options);
            return <TagLabel color={'purpleLight'}>{formattedDate}</TagLabel>;
          }}
        />
        {showSoldColumn && (
          <>
            <Column
              title="Sales"
              dataIndex="sales"
              key="sales"
              render={(_name, record: MarketplaceMetrics) => {
                return record.sales || '-';
              }}
            />
            <Column
              title="Revenue"
              dataIndex="revenue"
              key="revenue"
              render={(_name, record: MarketplaceMetrics) => {
                if (!record.revenue) return '-';

                return record.revenue.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                });
              }}
            />
          </>
        )}
      </Table>
      <Spacer type={'stack'} size={'m'} />
      <LabelBodyB>Totals</LabelBodyB>
      <Table pagination={false} scroll={{ y: 500 }} className="wide-table" dataSource={[metricTotals]} rowKey="name">
        <Column
          title="Active Posts"
          dataIndex="activePosts"
          key="activePosts"
          render={(_name, record: MarketplaceMetrics) => {
            return record.activePosts || '-';
          }}
        />
        <Column
          title="Total Posts"
          dataIndex="totalPosts"
          key="totalPosts"
          render={(_name, record: MarketplaceMetrics) => {
            return record.totalPosts || '-';
          }}
        />
        <Column
          title="Leads"
          dataIndex="leads"
          key="leads"
          render={(_name, record: MarketplaceMetrics) => {
            return record.leads || '-';
          }}
        />
        {showSoldColumn && (
          <>
            <Column
              title="Sales"
              dataIndex="sales"
              key="sales"
              render={(_name, record: MarketplaceMetrics) => {
                return record.sales || '-';
              }}
            />
            <Column
              title="Revenue"
              dataIndex="revenue"
              key="revenue"
              render={(_name, record: MarketplaceMetrics) => {
                if (!record.revenue) return '-';

                return record.revenue.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                });
              }}
            />
          </>
        )}
      </Table>
    </CardStyled>
  );
};
