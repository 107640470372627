import React from 'react';

import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { RestrictedRoute } from '../components/ui/Security';
import { SideDrawerProvider } from '../context/SideDrawerProvider';
import { RoleEnum } from '../models/session';
import Dashboard from '../pages/Dashboard';
import { OrganizationAccounts } from '../pages/OrganizationAccounts';
import { UserProfile } from '../pages/UserProfile';
import InboxRoutes from './InboxRoutes';
import InventoryRoutes from './InventoryRoutes';
import QueueRoutes from './QueueRoutes';
import SettingsRoutes from './SettingsRoutes';
import UserRoutes from './UserRoutes';
import { useSession } from '../context/AppStateProvider';
import { getDefaultHome } from '../pages/Login';
import { PartnerAccounts } from '../pages/PartnerAccounts';
import ExtensionErrorRedirect from '../pages/ExtensionErrorRedirect/ExtensionErrorRedirect';
import ExtensionSalesSurvey from '../pages/ExtensionSalesSurvey/ExtensionSalesSurvey';
import OnboardingDashboard from '../pages/OnboardingDashboard/OnboardingDashboard';

const AppRoutes: React.FC = () => {
  const { path } = useRouteMatch();
  const { session } = useSession();
  const defaultHome = getDefaultHome(session?.roles);

  return (
    <Switch>
      <RestrictedRoute
        forbiddenRoles={[RoleEnum.Admin, RoleEnum.Member]}
        path={`${path}/inbox/:organizationId?`}
        component={InboxRoutes}
      />
      <Route path={`${path}/users/:organizationId?`} component={UserRoutes} />
      <Route path={`${path}/partner/users/:partnerId`} component={UserRoutes} />
      <Route exact path={`${path}/profile`}>
        <SideDrawerProvider>
          <UserProfile />
        </SideDrawerProvider>
      </Route>
      <RestrictedRoute exact path={`${path}/dashboard`} component={Dashboard} />
      <RestrictedRoute forbiddenRoles={[RoleEnum.Member]} path={`${path}/inventory`} component={InventoryRoutes} />
      <RestrictedRoute
        allowedRoles={[RoleEnum.SuperAdmin]}
        exact
        path={`${path}/onboarding`}
        component={OnboardingDashboard}
      />
      <RestrictedRoute
        allowedRoles={[RoleEnum.SuperAdmin, RoleEnum.Partner]}
        exact
        path={`${path}/partner/:partnerId?`}
      >
        <SideDrawerProvider>
          <PartnerAccounts />
        </SideDrawerProvider>
      </RestrictedRoute>
      <RestrictedRoute
        forbiddenRoles={[RoleEnum.Admin, RoleEnum.Member]}
        exact
        path={`${path}/organization/:organizationId?`}
      >
        <SideDrawerProvider>
          <OrganizationAccounts />
        </SideDrawerProvider>
      </RestrictedRoute>
      <Route path={`${path}/queue`} component={QueueRoutes} />
      <Route path={`${path}/settings`} component={SettingsRoutes} />
      <Route path={`${path}/chrome-extension-error`} component={ExtensionErrorRedirect} />
      <Route path={`${path}/chrome-extension-survey`} component={ExtensionSalesSurvey} />
      <Redirect to={defaultHome} />
    </Switch>
  );
};

export default AppRoutes;
